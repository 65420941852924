<button mat-icon-button routerLink="/notifications" *ngVar="service.unreadNotifications$ | async as unreadNotifications">
  <mat-icon
    matBadgeColor="accent"
    [matBadge]="unreadNotifications > 99 ? '∞' : unreadNotifications === 0 ? '' : unreadNotifications"
    [class.infinity]="unreadNotifications > 99"
    [matTooltip]="('notifications' | intl) + ': ' + unreadNotifications"
  >
    {{ unreadNotifications === 0 ? 'notifications_none' : 'notifications' }}
  </mat-icon>
</button>
