import { Injectable } from '@angular/core';
import { GraphqlClientService } from '@rcg/graphql';
import { firstValueFrom, map, retry, shareReplay } from 'rxjs';
import {
  deleteNotificationMutation,
  fcmAllMessagesSubscription,
  openAllNotificationsMutation,
  openNotificationMutation,
  unreadNotificationsSubscription,
} from '../gql/notifications.gql';
import { RcgFcmMessage } from '../models/fcm_message.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  readonly unreadNotifications$ = this.gql
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .subscribe<any>({
      query: unreadNotificationsSubscription,
    })
    .pipe(
      map((data) => data?.global_fcm_messages_aggregate?.aggregate?.count),
      retry({ delay: 1000 }),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );

  constructor(private gql: GraphqlClientService) {}

  notifications$(index: number) {
    return this.gql
      .subscribe<{ global_fcm_messages?: RcgFcmMessage[] }>({
        query: fcmAllMessagesSubscription,
        variables: {
          limit: 25,
          offset: 25 * index,
        },
      })
      .pipe(map((data) => data?.global_fcm_messages ?? []));
  }

  openNotification({ id }: RcgFcmMessage) {
    return firstValueFrom(
      this.gql.mutate({
        mutation: openNotificationMutation,
        variables: {
          id,
          opened_at: 'now()',
        },
      }),
    );
  }

  deleteNotification({ id }: RcgFcmMessage) {
    return firstValueFrom(
      this.gql.mutate({
        mutation: deleteNotificationMutation,
        variables: {
          id,
        },
      }),
    );
  }

  openAllNotifications() {
    return firstValueFrom(
      this.gql.mutate({
        mutation: openAllNotificationsMutation,
      }),
    );
  }
}
