import { gql } from "@apollo/client/core";

export const unreadNotificationsSubscription = gql`
subscription UnreadNotificationsSubscription {
  global_fcm_messages_aggregate(
    where: {
      opened_at: {_is_null: true}
    }
  ) {
    aggregate{
      count
    }
  }
}
`;

export const fcmAllMessagesSubscription = gql`
subscription FcmMessagesSubscription(
  $limit: Int
  $offset: Int
) {
  global_fcm_messages(
    order_by: {
      sent_at: desc_nulls_first
    }
    limit: $limit,
    offset: $offset,
  ) {
    id
    sent_at
    opened_at
    title
    body
    attributes
  }
}
`;

export const openNotificationMutation = gql`
mutation OpenNotificationMutation(
  $id: bigint!
  $opened_at: timestamptz
) {
  update_global_fcm_messages_by_pk(
    pk_columns: {
      id: $id
    }
    _set: {
      opened_at: $opened_at
    }
  ) {
    id
  }
}
`;

export const deleteNotificationMutation = gql`
mutation DeleteNotificationMutation(
  $id: bigint!
) {
  update_global_fcm_messages_by_pk(
    pk_columns: {
      id: $id
    }
    _set: {
      is_deleted: true
    }
  ) {
    id
  }
}
`;

export const openAllNotificationsMutation = gql`
mutation OpenAllNotificationsMutation {
  update_global_fcm_messages(
    where: {
      opened_at: {_is_null: true}
    }
    _set: {
      opened_at: "now()"
    }
  ) {
    affected_rows
  }
}
`;
