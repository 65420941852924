import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subscription,
  combineLatest,
  debounceTime,
  filter,
  map,
  pairwise,
  share,
  startWith,
  switchMap,
  tap,
  timer,
} from 'rxjs';
import { RcgFcmMessage } from '../../models/fcm_message.model';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'rcg-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationListComponent implements AfterViewInit, OnDestroy {
  @ViewChild('scrollViewport') scrollViewport?: CdkVirtualScrollViewport;

  readonly rawNotifications$ = new BehaviorSubject<Observable<RcgFcmMessage[]>[]>([]);

  readonly notifications$ = this.rawNotifications$.pipe(
    switchMap((items) => combineLatest(items)),
    map((items) => ([] as RcgFcmMessage[]).concat(...items)),
  );

  readonly selectedNotification$ = new BehaviorSubject<RcgFcmMessage | null>(null);

  private readonly lastLoadedIndex$ = new BehaviorSubject(-1);
  readonly loading$ = new BehaviorSubject(true);

  private scrollSub?: Subscription;

  constructor(public service: NotificationService) {}

  ngAfterViewInit(): void {
    this.scrollSub = this.scrollViewport
      ?.elementScrolled()
      .pipe(
        map(() => this.scrollViewport!.measureScrollOffset('bottom')),
        pairwise(),
        filter(([y1, y2]) => y2 < y1 && y2 < 140),
        startWith([-1, -1]),
        debounceTime(200),
      )
      .subscribe(() => this.fetchMore());
  }

  ngOnDestroy(): void {
    this.scrollSub?.unsubscribe();
    this.rawNotifications$.unsubscribe();
    this.lastLoadedIndex$.unsubscribe();
    this.selectedNotification$.unsubscribe();
    this.loading$.unsubscribe();
  }

  private fetchMore() {
    const index = this.rawNotifications$.value.length;
    if (index > this.lastLoadedIndex$.value + 1) return;

    this.loading$.next(true);

    this.rawNotifications$.next([
      ...this.rawNotifications$.value,
      this.service.notifications$(index).pipe(
        tap((val) => {
          if (index > this.lastLoadedIndex$.value) {
            if (val?.length) this.lastLoadedIndex$.next(index);
            this.loading$.next(false);
          }
        }),
        share({
          connector: () => new ReplaySubject(),
          resetOnRefCountZero: () => timer(5000),
        }),
      ),
    ]);
  }

  async selectNotification(event: MatSelectionListChange) {
    const selection = event.options[0];
    if (!selection) return this.selectedNotification$.next(null);

    const msg = selection.value as RcgFcmMessage;

    this.selectedNotification$.next(msg);
    await this.service.openNotification(msg);
  }

  async deleteNotification(msg: RcgFcmMessage) {
    await this.service.deleteNotification(msg);
    this.selectedNotification$.next(null);
  }
}
