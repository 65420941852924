import { AfterViewInit, Component, EventEmitter, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ToastClickEventArgs, ToastComponent } from '@syncfusion/ej2-angular-notifications';
import { ReplaySubject, firstValueFrom } from 'rxjs';

const dataClass = 'rcg-toast-data';
const dataAttr = 'data';

@Component({
  selector: 'rcg-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationComponent implements AfterViewInit {
  @ViewChild('toast') private toast?: ToastComponent;
  @Output() toastClick = new EventEmitter<Record<string, unknown> | undefined>();

  private hasViewInit = new ReplaySubject<boolean>(1);

  ngAfterViewInit(): void {
    this.hasViewInit.next(true);
  }

  onToastClick(uArgs: unknown) {
    const args = uArgs as ToastClickEventArgs;

    try {
      const data = args.element
        .querySelector<HTMLPreElement>(`.${dataClass}`)
        ?.getAttribute(dataAttr)
        ?.replaceAll('__RCG_QUOT__', '"')
        .replaceAll('__RCG_BS__', '\\');
      if (!data) throw 'No data';
      this.toastClick.next(data ? JSON.parse(data) : undefined);
    } catch (error) {
      console.error('onToastClick error', error);
    }
  }

  async showToast({
    title,
    body,
    icon,
    timeOut,
    data,
  }: {
    title?: string;
    body?: string;
    icon?: string;
    timeOut?: number;
    data?: Record<string, unknown>;
  }) {
    const content = document.createElement('div');

    const dataEl = document.createElement('pre');
    dataEl.classList.add(dataClass);
    dataEl.style.display = 'none';
    dataEl.setAttribute(dataAttr, JSON.stringify(data).replaceAll('"', '__RCG_QUOT__').replaceAll('\\', '__RCG_BS__'));
    content.appendChild(dataEl);

    const bodyEl = document.createElement('span');
    bodyEl.innerText = body ?? '';
    content.appendChild(bodyEl);

    await firstValueFrom(this.hasViewInit);

    this.toast!.show({
      showProgressBar: true,
      progressDirection: 'Ltr',
      title,
      content: content.outerHTML,
      icon,
      timeOut: timeOut ?? 5000,
    });
  }
}
