<mat-toolbar class="module-title">
  <span>{{ 'notifications' | intl }}</span>
  <div style="flex: 1"></div>
  <div>
    <button
      mat-icon-button
      *ngVar="service.unreadNotifications$ | async as unreadNotifications"
      [matTooltip]="'mark_all_as_read' | intl"
      [disabled]="unreadNotifications === 0"
      (click)="service.openAllNotifications()"
    >
      <mat-icon [color]="unreadNotifications === 0 ? null : 'primary'">done_all</mat-icon>
    </button>
  </div>
</mat-toolbar>

<cdk-virtual-scroll-viewport #scrollViewport class="nav" itemSize="72">
  <mat-selection-list [multiple]="false" (selectionChange)="selectNotification($event)">
    <mat-list-option
      *cdkVirtualFor="let msg of notifications$ | async"
      [value]="msg"
      [disabled]="msg.remote_sdi"
      [selected]="(selectedNotification$ | async)?.id === msg?.id"
    >
      <mat-icon matListItemIcon [style.color]="msg.opened_at ? '' : 'orange'">{{
        msg.opened_at ? 'notifications' : 'notifications_active'
      }}</mat-icon>
      <span matListItemTitle>{{ msg.title }} &#64; {{ msg.sent_at | date : 'medium' }}</span>
      <span matListItemLine style="color: gray">{{ msg.body }}</span>
    </mat-list-option>
    <div *ngIf="loading$ | async" class="nav-spinner-container">
      <mat-spinner mode="indeterminate" [diameter]="50"></mat-spinner>
    </div>
  </mat-selection-list>
</cdk-virtual-scroll-viewport>

<div *ngIf="((notifications$ | async)?.length ?? 0) === 0 && (loading$ | async) === false" class="no-messages">
  <mat-icon>notifications_none</mat-icon>
  <h2 class="description">{{ 'no_notifications' | intl }}</h2>
</div>

<main *ngIf="selectedNotification$ | async as msg; else notSelectedTemplate">
  <h3>{{ msg.title }}</h3>
  <h5>{{ msg.sent_at | date : 'medium' }}</h5>
  <span>{{ msg.body }}</span>

  <div class="action-bar">
    <button mat-raised-button color="primary" disabled>{{ 'open' | intl }}</button>
    <!-- TODO: run action -->
    <button mat-raised-button color="warn" (click)="deleteNotification(msg)">{{ 'clear' | intl }}</button>
  </div>
</main>

<ng-template #notSelectedTemplate>
  <div class="not-selected">
    <mat-icon>notifications_off</mat-icon>
    <h2 class="description">{{ 'choose_notification' | intl }}</h2>
  </div>
</ng-template>
